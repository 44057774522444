import general from '../../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import { FormType } from './enum'
import moment, { min } from 'moment';
import axios from 'axios';

export const useController = () => {
    const [loading, setLoading] = useState(false);
    const [formExists, setFormExists] = useState(false);
    const [form, setForm] = useState<any>(null);
    const [pickDateModalActive, setPickDateModalActive] = useState(true);
    const [timeCounter, setTimeCounter] = useState(0);
    const [extraInfo, setExtraInfo] = useState<any>({});
    const [formSended, setFormSended] = useState(false);
    const [redirectCounter, setRedirectCounter] = useState(5);
    const [answer, setAnswer] = useState<{
        id: string,
        received_at: string,
        time_to_answer: number,
        extra_info: any,
        fields: {
            id: string,
            title: string,
            type: string,
            required: boolean,
            min_date?: any,
            value: any
        }[]
    }>({
        id: general.randomString(10),
        received_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        time_to_answer: 0,
        extra_info: {},
        fields: []
    });

    const getFormFromSlug = async (showLoader?: boolean) => {
        try {
            const slug = window.location.pathname.split('/').pop();
            showLoader == true && setLoading(true);
            const response = await general.api.get('public/form/slug/' + slug);
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setLoading(false);
                    return;
                }
                setForm(response.data.data);
                setAnswer({
                    ...answer, fields: response.data.data.fields_array.map((field: any) => {
                        return {
                            id: field.id,
                            title: field.title,
                            type: field.type,
                            required: field.required,
                            value: null
                        }
                    })
                });
                setFormExists(true);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar formulário', 'error');
            setLoading(false);
        }
    }

    const verifySendRequest = () => {
        let hasEmptyFields = false;
        answer.fields.map((field: any) => {
            if (field.required && !field.value) {
                hasEmptyFields = true;
            }
        });
        if (hasEmptyFields) {
            general.notify('Preencha todos os campos obrigatórios', 'error');
            return false;
        }
        return true;
    }

    const saveForm = async () => {
        if (!verifySendRequest()) return;
        setLoading(true);
        answer.fields = answer.fields.map((field: any) => {
            if (field.type == 'date') {
                return {
                    ...field, value: moment(field.value).format('DD/MM/YYYY')
                }
            }
            return field;
        });
        general.api.post('public/form/answer/' + form._id, {
            ...answer,
            time_to_answer: timeCounter,
            extra_info: extraInfo,
            received_at: moment().format('YYYY-MM-DD HH:mm:ss')
        }).then((response: any) => {
            if (response?.data?.status == 403) {
                return general.notify(response.data.message, 'error');
            }
            response.data.status === 200 ? general.notify('Resposta enviada com sucesso', 'success') : general.notify(`Erro ao tentar realizar a ação - [${response.data.message}]`, 'error');
            setFormSended(true);
            if(form.redirect_to){
                setTimeout(() => {
                    window.location.href = form.redirect_to;
                }, 5000);

                setInterval(() => {
                    setRedirectCounter(counter => counter - 1);
                }, 1000);
            }
        }).catch((error: any) => {
            general.notify('Erro ao tentar realizar a ação', 'error');
            console.log(error);
            setLoading(false);
        }).then(() => {
            setLoading(false);
        });
    }

    const changeField = (fieldId: string, value: any) => {
        setAnswer({
            ...answer, fields: answer.fields.map((field: any) => {
                if (field.id == fieldId) {
                    return { ...field, value: value }
                }
                return field;
            })
        });
    }

    useEffect(() => {
        getFormFromSlug(true);
        getExtraInfo();
        setInterval(() => {
            setTimeCounter(timer => timer + 1);
        }, 1000);
    }, []);

    const countFormView = async () => {
        try {
            await general.api.post('public/form/view/count/' + form._id);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (form) {
            countFormView();

            if(form.form_type == FormType.EMPLOYEE){
                let fields = form.fields_array;
                fields.unshift({
                    id: 'job_title',
                    title: 'Função na empresa (Cargo)',
                    type: 'text',
                    required: false,
                    options: []
                });
                setAnswer({
                    ...answer, fields: fields.map((field: any) => {
                        return {
                            id: field.id,
                            title: field.title,
                            type: field.type,
                            required: field.required,
                            value: null
                        }
                    })
                });
            }

            if (form.form_type != FormType.SURVEY && form.form_type != FormType.SCHEDULE && form.form_type != FormType.LEAD) {
                let fields = form.fields_array;
                fields.unshift({
                    id: 'person_email',
                    title: 'Email',
                    type: 'text',
                    required: true,
                    options: []
                });
                fields.unshift({
                    id: 'person_name',
                    title: 'Nome',
                    type: 'text',
                    required: true,
                    options: []
                });
                setAnswer({
                    ...answer, fields: fields.map((field: any) => {
                        return {
                            id: field.id,
                            title: field.title,
                            type: field.type,
                            required: field.required,
                            value: null
                        }
                    })
                });
                setForm({ ...form, fields_array: fields });
            }

            if(form.form_type == FormType.LEAD){
                let fields = form.fields_array;
                fields.unshift({
                    id: 'name',
                    title: 'Nome',
                    type: 'text',
                    required: false,
                    options: []
                });
                setAnswer({
                    ...answer, fields: fields.map((field: any) => {
                        return {
                            id: field.id,
                            title: field.title,
                            type: field.type,
                            required: field.required,
                            value: null
                        }
                    })
                });
            }

            if (form.form_type == FormType.SCHEDULE) {
                let fields = form.fields_array;
                let allowed_times = [];
                let day_start_in_minutes = parseInt(form.schedule.time_day_start);
                let day_end_in_minutes = parseInt(form.schedule.time_day_end);
                let time_in_minutes_for_booking = parseInt(form.schedule.time_in_minutes);

                while ((day_start_in_minutes + time_in_minutes_for_booking) <= day_end_in_minutes) {
                    let time = moment().startOf('day').add(day_start_in_minutes, 'minutes').format('HH:mm');
                    allowed_times.push(time);
                    day_start_in_minutes += time_in_minutes_for_booking;
                }

                fields.unshift({
                    id: 'person_name',
                    title: 'Nome',
                    type: 'text',
                    required: true,
                    options: []
                });
                fields.push({
                    id: 'chosen_date',
                    title: 'Data de agendamento',
                    type: 'date',
                    required: true,
                    min_date: new Date(),
                    allowed_days: form?.schedule?.allowed_days,
                    options: []
                });
                fields.push({
                    id: 'chosen_time',
                    title: 'Hora de agendamento ' + `(${time_in_minutes_for_booking} minutos)`,
                    type: 'select',
                    required: true,
                    options: allowed_times.map((time: any) => {
                        return {
                            value: `De ${time} até ${moment().startOf('day').add(moment(time, 'HH:mm').add(time_in_minutes_for_booking, 'minutes').format('HH:mm')).format('HH:mm')}.`,
                            label: time.toString()
                        }
                    }),
                    value: allowed_times.map((time: any) => {
                        return {
                            value: `De ${time} até ${moment().startOf('day').add(moment(time, 'HH:mm').add(time_in_minutes_for_booking, 'minutes').format('HH:mm')).format('HH:mm')}.`,
                            label: time.toString()
                        }
                    })[0]?.value
                });
                setAnswer({
                    ...answer, fields: fields.map((field: any) => {
                        let newField: any = {
                            id: field.id,
                            title: field.title,
                            type: field.type,
                            required: field.required,
                            value: field.value ?? null
                        }
                        if (field.id == 'chosen_date') {
                            newField.min_date = new Date();
                        }
                        return newField
                    })
                });
            }
        }
    }, [formExists]);

    const getExtraInfo = async () => {
        try {
            setExtraInfo(await general.getIpAndLocation());
        }
        catch (err) {
            console.log(err);
        }

    }



    return {
        general,
        FormType,
        form,
        loading,
        answer,
        pickDateModalActive,
        formSended,
        redirectCounter,
        setPickDateModalActive,
        changeField,
        saveForm
    }
}

export default useController;