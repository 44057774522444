import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../../utils/types';
import MenuComponent from '../../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../../components/app/HeaderBarComponent';
import ActionCardAlt from '../../../../components/app/Cards/ActionCardAlt';
import CardForm from '../../../../components/app/Cards/CardForm';
import Pagination from '../../../../components/general/Pagination';
import FormModal from '../FormModal';
import { useGlitch } from 'react-powerglitch'
import Response from '../../../../components/app/Cards/ResponseCard';

const DeleteForm = (props: any) => {
    return (
        <S.deleteFormWrapper active={props.controller.deleteModalActive}>
            <S.deleteForm>
                {props.controller.formDeleted ? (
                    <S.deleteFormTitle>
                        Formulário excluído com sucesso, redirecionando...
                    </S.deleteFormTitle>
                ) : (
                    <>
                        <S.deleteFormTitle>
                            Tem certeza que deseja excluir este formulário?
                        </S.deleteFormTitle>
                        <S.deleteFormButtons>
                            <S.deleteFormButton warning onClick={() => props.controller.deleteForm()}>
                                Excluir
                            </S.deleteFormButton>
                            <S.deleteFormButton onClick={() => props.controller.setDeleteModalActive(false)}>
                                Cancelar
                            </S.deleteFormButton>
                        </S.deleteFormButtons>
                    </>
                )}
            </S.deleteForm>
        </S.deleteFormWrapper>
    );
}

const EditForm = (props: defaultProps) => {
    const controller = useController();
    const glitch = useGlitch();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName={`Opções do formulário ${controller.form?.title != '' ? `- [${controller.form?.title}]` : ''}`} />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            <DeleteForm controller={controller} />
            {controller.editModalActive ? <FormModal type='edit' modalActive={controller.editModalActive} setModalActive={controller.setEditModalActive} currentItem={controller.form} /> : null}
            <controller.general.DefaultLoading active={controller.loading} />
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName={controller.form ? `Opções do formulário ${controller.form?.title != '' ? `- [${controller.form?.title}]` : ''}` : 'Formulário não encontrado'} />
                {controller.form ? (
                    controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                        <S.operationsArea>
                            <div className='content-cards-wrapper' onClick={() => controller.general.GoToRoute('/app/forms')}>
                                <ActionCardAlt mainIcon='faLeftLong'>
                                    Voltar
                                </ActionCardAlt>
                            </div>
                            <div className='content-cards-wrapper' onClick={() => controller.setEditModalActive(active => !active)}>
                                <ActionCardAlt mainIcon='faFilePen'>
                                    Editar <br /> formulário
                                </ActionCardAlt>
                            </div>
                            <div className='content-cards-wrapper' onClick={() => controller.setDeleteModalActive(active => !active)}>
                                <ActionCardAlt mainIcon='faTrash'>
                                    Excluir <br /> formulário
                                </ActionCardAlt>
                            </div>
                            {/* {(controller.form?.form_type != controller.FormType.SCHEDULE && controller.form?.form_type != controller.FormType.SURVEY) && (
                                <div className='content-cards-wrapper' onClick={() => controller.setEditModalActive(active => !active)}>
                                    <ActionCardAlt mainIcon='faEnvelopesBulk'>
                                        Enviar emails para <br /> os cadastrados
                                    </ActionCardAlt>
                                </div>
                            )} */}
                            <div className='content-cards-wrapper' onClick={() => {
                                controller.general.copyToClipboard(`${process.env.REACT_APP_BASE_URL}io/form/${controller.form?.form_slug}`)
                                controller.general.notify('Link copiado', 'success');
                            }}>
                                <ActionCardAlt mainIcon='faCopy'>
                                    Copiar <br /> Link
                                </ActionCardAlt>
                            </div>
                            <div className='content-cards-wrapper' onClick={() => {
                                controller.general.notify('Apenas um botão informativo', 'info');
                            }}>
                                <ActionCardAlt mainIcon={controller.form?.answers?.length > 0 ? 'faComments' : 'faCommentSlash'}>
                                    {controller.form?.answers?.length > 0 ? `Há ${controller.form?.answers?.length}` : 'Não há'} <br /> {controller.form?.answers?.length > 1 ? 'respostas' : 'resposta'}
                                </ActionCardAlt>
                            </div>
                            {(controller?.form?.answers?.reverse() ?? []).map((item: any, index: number) => {
                                return (
                                    <div className='content-cards-wrapper' onClick={() => controller.general.notify('Card apenas de visualização', 'info')} key={`supplier_${index}`}>
                                        <Response
                                            title={'Resposta - ' + item.id ?? 'Não informado'}
                                            subtitle={item.received_at ? (item.received_at.split(' ')[0].split('-').reverse().join('/') + ' ' + item.received_at.split(' ')[1]) : 'Não informado'}
                                            infos={[...item?.fields?.map((field: any) => {
                                                return {
                                                    key: field.id,
                                                    value: field.type == 'date' ? field.value.split('T')[0].split('-').reverse().join('/') : (field.type == 'checkbox' ? (field.value ? 'Sim' : 'Não') : field.value),
                                                    name: field.title
                                                }
                                            }), {
                                                key: 'time_to_answer',
                                                value: item.time_to_answer,
                                                name: 'Tempo para responder'
                                            }, {
                                                key: 'country',
                                                value: item.extra_info?.country ?? 'Não informado',
                                                name: 'Páis de origem'
                                            }, {
                                                key: 'region',
                                                value: item.extra_info?.regionName ?? 'Não informado',
                                                name: 'Região'
                                            }, {
                                                key: 'city',
                                                value: item.extra_info?.city ?? 'Não informado',
                                                name: 'Cidade de origem'
                                            }, {
                                                key: 'timezone',
                                                value: item.extra_info?.timezone ?? 'Não informado',
                                                name: 'Fuso horário'
                                            } ]} />
                                    </div>
                                )
                            })}
                        </S.operationsArea>
                    )
                ) : (
                    <S.formNotFound>
                        <div ref={glitch.ref}>
                            <S.formNotFoundIcon icon={controller.general.icons.faFileCircleXmark} />
                        </div>
                        <S.formNotFoundTitle ref={glitch.ref}>
                            Formulário não encontrado
                        </S.formNotFoundTitle>
                    </S.formNotFound>
                )}
            </S.generalArea>
        </S.main>
    );
}

export default EditForm;