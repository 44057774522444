import general from '../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import { defaultItemModalProps } from '../../../utils/types';
export const useController = (props: defaultItemModalProps) => {
    const [modalActive, setModalActive] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [item, setItem] = useState(props.item);
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [incrementStockModal, setIncrementStockModal] = useState(false);
    const [amountToIncrement, setAmountToIncrement] = useState(1);
    const [commentInput, setCommentInput] = useState('');
    const [optionShowing, setOptionShowing] = useState<'comments' | 'form'>('form');
    const inputsListRef = useRef<any>();
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        if (!props.modalActive) {
            setTimeout(() => {
                setModalActive(props.modalActive);
            }, 300);
        }
        else {
            setModalActive(props.modalActive);
        }
    }, [props.modalActive]);

    useEffect(() => {
        if (modalActive) {
            setTimeout(() => {
                setModalShow(modalActive);
            }, 100);
        }
        else {
            setModalShow(modalActive);
        }
    }, [modalActive]);

    const incrementStock = () => {
        let stockInput = document.getElementById("value_stock") as HTMLInputElement
        stockInput.value = (parseFloat(stockInput.value) + amountToIncrement).toString();
        setIncrementStockModal(false);
        sendRequest(false);
        general.notify('Estoque incrementado com sucesso', 'success');
    }

    const fieldsUpdate = () => {
        let nameFields = document.querySelectorAll('input[name^="key_"]');
        let valueFields = document.querySelectorAll('input[name^="value_"]');
        let newInfos = item.infos;
        nameFields.forEach((field: any) => {
            let key = field.name.split('_').slice(1).join('_')
            newInfos = newInfos?.map((info: any) => {
                if (info.key == key) {
                    info.name = field.value;
                }
                return info;
            });
        });
        valueFields.forEach((field: any) => {
            let key = field.name.split('_').slice(1).join('_')
            newInfos = newInfos?.map((info: any) => {
                if (info.key == key) {
                    info.value = field.value;
                }
                return info;
            });
        });
    }

    const addNewItemField = () => {
        fieldsUpdate();

        let randomString = general.randomString(5)
        setItem((prev: any) => ({
            ...prev, infos: [...prev.infos, {
                key: randomString,
                name: 'Nome do campo',
                type: 'text',
                value: ''
            }]
        }));
        setTimeout(() => {
            if (inputsListRef.current) {
                let inputsFormList = document.getElementById('inputsFormList');
                inputsFormList!.scrollTo({
                    top: inputsListRef.current.scrollHeight,
                    behavior: 'smooth'
                })
            }
        }, 200);
    }

    const makeRequestObject = () => {
        fieldsUpdate();
        let requestObject: any = {};
        requestObject['infoInputs'] = [];
        requestObject['timeline'] = item.timeline ?? [];
        item.infos?.forEach((info: any) => {
            const element = document.getElementById(`value_${info.key}`) as HTMLInputElement
            requestObject[info.key] = element.type == 'checkbox' ? element.checked : element.value;
            requestObject['infoInputs'] = [...requestObject['infoInputs'], {
                key: info.key,
                type: info.type,
                name: info.name,
                value: element.type == 'checkbox' ? element.checked : element.value,
                disabled: info.disabled ?? false,
                mask: info.mask ?? null,
                required: info.required ?? false,
                selectOptions: info.selectOptions ?? []
            }];
        })
        return requestObject;
    }

    const sendRequest = async (closeModal?: boolean) => {
        setLoading(true);
        if(props.replaceRequest){
            props.replaceRequest(makeRequestObject());
            return;
        }
        await general.api.post(props.url, makeRequestObject()).then((response: any) => {
            if (closeModal) {
                props.setModalActive((active: boolean) => !active)
                if(response?.data?.status == 403){
                    return general.notify(response.data.message, 'error');
                }
                response.data.status === 200 ? general.notify(`${response.data.message}`, 'success') : general.notify(`Erro ao tentar realizar a ação - [${response.data.message}]`, 'error');
            };
            setLoading(false);
        }).catch((error: any) => {
            general.notify('Erro ao tentar realizar a ação', 'error');
            console.log(error);
            setLoading(false);
        });
    }

    const makeCommentItem = () => {
        let newTimeline: any = item.timeline ?? [];
        let accountName = general.getAccount().name.split(" ")
        if(commentInput.length == 0) return newTimeline.sort((a: any, b: any) => {
            return  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        newTimeline = [...newTimeline, {
            account_id: general.getAccount()._id,
            title: `${accountName[0]} ${accountName.length > 1 ? accountName[accountName.length - 1] : ''}`,
            message: commentInput,
            avatar_url: general.getAccount().avatarUrl,
            createdAt: general.getCurrentDateTime(),
            fromSystem: false
        }];
        return newTimeline.sort((a: any, b: any) => {
            return  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
    }

    const sendComment = async () => {
        setItem((prev: any) => ({
            ...prev, timeline: makeCommentItem()
        }))
    }

    useEffect(() => {
        if(commentInput.length > 0){
            sendRequest(false);
            setCommentInput('');
        }
    }, [item.timeline]);

    const selectWithSearch = (option: any) => {
        let value = option.value
        let key = option.key
        setItem((prev: any) => ({
            ...prev, infos: prev.infos?.map((info: any) => {
                if (info.key == key) {
                    info.value = value;
                }
                return info;
            })
        }));
    }

    const deleteItem = async () => {
        setDeleteLoading(true);
        general.api.delete(props.url.replace('update', 'delete')).then((response: any) => {
            props.setModalActive((active: boolean) => !active);
            setDeleteLoading(false);
            if (response?.data?.status == 403) {
                return general.notify(response.data.message, 'error');
            }
            general.notify(`${response.data.message}`, 'success');
        }).catch((error: any) => {
            console.log(error);
            general.notify('Erro ao tentar realizar a ação', 'error');
            setDeleteLoading(false);
        });
    }

    const handleNameChange = (e: any, id: string) => {
        document.getElementById(id)!.style.width = (e.target.value.length > 0 ? (e.target.value.length + 2) : 'Digite um nome para o campo'.length) + 'ch';
    }

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                props.setModalActive(false);
            }
        })
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
            }
        })
    }, []);

    return {
        general,
        modalActive,
        modalShow,
        item,
        setItem,
        addNewItemField,
        handleNameChange,
        inputsListRef,
        optionShowing,
        setOptionShowing,
        sendRequest,
        deleteItem,
        deleteConfirmationModal,
        setDeleteConfirmationModal,
        incrementStock,
        incrementStockModal,
        setIncrementStockModal,
        amountToIncrement,
        setAmountToIncrement,
        commentInput,
        setCommentInput,
        sendComment,
        loading,
        deleteLoading,
        selectWithSearch
    };
}

export default useController;