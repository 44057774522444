import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultItemProps } from '../../../../utils/types';
import Avatar from 'react-avatar';
import {colors} from '../../../../utils/colors';

const ResponseCard = (props: defaultItemProps) => {
    const controller = useController();

    return (
        <S.main>
            <S.card>
                <S.cardData>
                    <S.itemName>{(props.title!.split(' ').length > 1 ? `${props.title?.split(' ')[0]} ${props.title?.split(' ')[props.title?.split(' ').length - 1]}` : props.title) ?? 'Título não informado'}</S.itemName>
                    <S.itemRole>{props.subtitle ?? 'Subtítulo não informado'}</S.itemRole>
                    <S.cardInfo>
                        {props.infos && props.infos.length > 0 ? props.infos.map((info, index) => {
                            return (
                                <S.itemInfo key={index}>
                                    <S.itemInfoName>{info.name}:</S.itemInfoName>
                                    <S.itemInfoValue dangerouslySetInnerHTML={{__html: `<span>${(info.value != '' && info.value) ? (info.type === 'select' ? info.selectOptions?.filter(option => option.value === info.value)[0].name : (info.type === 'date' ? info.value?.toString().split('T')[0].split('-').reverse().join('/') : info.value)) : 'Não informado'}</span>`}}></S.itemInfoValue>                                </S.itemInfo>
                            )
                        }) : (
                            <S.noInfo>Esse card não contém nenhuma informação extra.</S.noInfo>
                        )}
                    </S.cardInfo>
                </S.cardData>
            </S.card>

        </S.main>
    );
}

export default ResponseCard;