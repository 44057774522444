import React, { useEffect, useState } from 'react';
import * as S from './styles';
import useController from './controller';
import DefaultLoading from '../../../../components/app/DefaultLoading';
import { Text } from '../../../../components/general/inputs/Text';
import { QuillTextArea } from '../../../../components/general/inputs/QuillTextArea';
import { Checkbox } from '../../../../components/general/inputs/Checkbox';
import { Select } from '../../../../components/general/inputs/Select';

const FormModal = (props: {
    type: 'edit' | 'create';
    form?: any;
    modalActive: boolean;
    setModalActive: Function;
    currentItem?: any;
}) => {
    const controller = useController(props);

    return (
        props.modalActive ?
            <S.main>
                <S.modal>
                    <DefaultLoading active={controller.loading} />
                    <S.header>
                        <S.title>{props.type === 'create' ? 'Criar formulário' : 'Editar formulário'}</S.title>
                        <S.close onClick={() => props.setModalActive(false)}>X</S.close>
                    </S.header>
                    <S.fields>
                        <S.field>
                            <Text
                                name='title'
                                required={true}
                                label='Título do formulário:'
                                value={props.currentItem?.title ?? controller.form.title ?? 'Não informado'}
                                onChange={(e: any) => controller.setForm({ ...controller.form, title: e.target.value })}
                            />
                        </S.field>
                        <S.field>
                            <QuillTextArea
                                name='description'
                                label='Conteúdo do formulário:'
                                value={props.currentItem?.description ?? controller.form.description ?? 'Não informado'}
                                onChange={(value: any) => controller.setForm({ ...controller.form, description: value })}
                            />
                        </S.field>
                        <S.field>
                            <Checkbox
                                name='form_thanks_active'
                                label='Página de agradecimento:'
                                checked={props.currentItem?.thanks_active ?? controller.form.thanks_active ?? false}
                                onChange={(e: any) => controller.setForm({ ...controller.form, thanks_active: e.target.checked })}
                            />
                        </S.field>
                        {controller?.form?.thanks_active ? (
                            <>
                                <S.field>
                                    <Text
                                        name='form_thanks_message'
                                        label='Mensagem de agradecimento:'
                                        value={props.currentItem?.thanks_message ?? controller.form.thanks_message ?? 'Não informado'}
                                        onChange={(e: any) => controller.setForm({ ...controller.form, thanks_message: e.target.value })}
                                    />
                                </S.field>
                                <S.field>
                                    <Text
                                        name='form_thanks_btn_title'
                                        label='Título do botão de agradecimento:'
                                        value={props.currentItem?.thanks_btn_title ?? controller.form.thanks_btn_title ?? 'Não informado'}
                                        onChange={(e: any) => controller.setForm({ ...controller.form, thanks_btn_title: e.target.value })}
                                    />
                                </S.field>
                                <S.field>
                                    <Text
                                        name='form_thanks_btn_link'
                                        label='Link do botão de agradecimento:'
                                        value={props.currentItem?.thanks_btn_link ?? controller.form.thanks_btn_link ?? 'Não informado'}
                                        onChange={(e: any) => controller.setForm({ ...controller.form, thanks_btn_link: e.target.value })}
                                    />
                                </S.field>
                            </>
                        ) : null}
                        <S.field>
                            <Text
                                name='form_redirect_to'
                                label='Redirecionar para (opcional):'
                                value={props.currentItem?.redirect_to ?? controller.form?.redirect_to ?? ''}
                                onChange={(e: any) => controller.setForm({ ...controller.form, redirect_to: e.target.value })}
                            />
                        </S.field>
                        <S.field>
                            <Select
                                name='form_type'
                                required={true}
                                label='Tipo de formulário:'
                                value={props.currentItem?.form_type ?? controller.form.form_type ?? 'survey'}
                                onChange={(e: any) => controller.setForm({ ...controller.form, form_type: e.target.value })}
                                options={[
                                    { value: 'survey', label: 'Pesquisa' },
                                    { value: 'client', label: 'Cliente' },
                                    { value: 'supplier', label: 'Fornecedor' },
                                    { value: 'employee', label: 'Colaborador' },
                                    { value: 'schedule', label: 'Agendamento' },
                                    { value: 'lead', label: 'Lead' },
                                ]}
                            />
                            {(controller.form.form_type != controller.FormType.SCHEDULE && controller.form.form_type != controller.FormType.LEAD && controller.form.form_type != controller.FormType.SURVEY) && (
                                <S.warning>
                                    OBS: Formulários de cliente, fornecedor e colaborador obrigatoriamente irão possuir campos de nome e email adicionados automaticamente.
                                </S.warning>
                            )}
                            {(controller.form.form_type == controller.FormType.LEAD) && (
                                <S.warning>
                                    OBS: Formulários de leads obrigatoriamente irão possuir campo de nome adicionado automaticamente.
                                </S.warning>
                            )}
                            {(controller.form.form_type == controller.FormType.SCHEDULE) && (
                                <S.warning>
                                    OBS: Formulários de agendamento obrigatoriamente irão possuir os campos de dia e horários para o agendamento, além do de nome.
                                </S.warning>
                            )}
                        </S.field>
                        {controller?.form?.form_type === 'schedule' ? (
                            <>
                                <S.field>
                                    <Select
                                        name='form_schedule_time_in_minutes'
                                        label='Tempo que será reservado:'
                                        value={props.currentItem?.schedule?.time_in_minutes ?? controller.form.schedule?.time_in_minutes ?? '15'}
                                        onChange={(e: any) => controller.setForm({ ...controller.form, schedule: { ...controller.form.schedule, time_in_minutes: e.target.value } })}
                                        options={[
                                            { value: 15, label: '15 minutos' },
                                            { value: 30, label: '30 minutos' },
                                            { value: 45, label: '45 minutos' },
                                            { value: 60, label: '1 hora' },
                                            { value: 90, label: '1 hora e 30 minutos' },
                                            { value: 120, label: '2 horas' },
                                            { value: 150, label: '2 horas e 30 minutos' },
                                            { value: 180, label: '3 horas' },
                                            { value: 210, label: '3 horas e 30 minutos' },
                                            { value: 240, label: '4 horas' },
                                            { value: 270, label: '4 horas e 30 minutos' },
                                            { value: 300, label: '5 horas' },
                                            { value: 330, label: '5 horas e 30 minutos' },
                                            { value: 360, label: '6 horas' },
                                            { value: 390, label: '6 horas e 30 minutos' },
                                            { value: 420, label: '7 horas' },
                                            { value: 450, label: '7 horas e 30 minutos' },
                                            { value: 480, label: '8 horas' },
                                        ]}
                                    />
                                </S.field>
                                <S.field>
                                    <Select
                                        name='form_schedule_time_day_start'
                                        label='Horário de início:'
                                        value={props.currentItem?.schedule?.time_day_start ?? controller.form.schedule?.time_day_start ?? ''}
                                        onChange={(e: any) => controller.setForm({ ...controller.form, schedule: { ...controller.form.schedule, time_day_start: e.target.value } })}
                                        options={new Array(48).fill(0).map((_, index) => {
                                            const hours = Math.floor(index / 2);
                                            const minutes = index % 2 === 0 ? '00' : '30';
                                            return {
                                                value: hours * 60 + (minutes === '30' ? 30 : 0),
                                                label: `${hours < 10 ? '0' + hours : hours}:${minutes}`
                                            }
                                        })}
                                    />
                                </S.field>
                                <S.field>
                                    <Select
                                        name='form_schedule_time_day_end'
                                        label='Horário de término:'
                                        value={props.currentItem?.schedule?.time_day_end ?? controller.form.schedule?.time_day_end ?? ''}
                                        onChange={(e: any) => controller.setForm({ ...controller.form, schedule: { ...controller.form.schedule, time_day_end: e.target.value } })}
                                        options={new Array(48).fill(0).map((_, index) => {
                                            const hours = Math.floor(index / 2);
                                            const minutes = index % 2 === 0 ? '00' : '30';
                                            return {
                                                value: hours * 60 + (minutes === '30' ? 30 : 0),
                                                label: `${hours < 10 ? '0' + hours : hours}:${minutes}`
                                            }
                                        })}
                                    />
                                </S.field>
                                <S.field>
                                    <S.label>Dias permitidos:</S.label>
                                    <S.allowedDays>
                                        {controller.general.days.map((day, index) => (
                                            <S.day active={controller.form.schedule.allowed_days.includes(day.label)} key={`day_${index}`} onClick={() => controller.toggleDay(day.label)}>
                                                <S.dayIcon icon={controller.form.schedule.allowed_days.includes(day.label) ? controller.general.icons.faCheck : controller.general.icons.faTimes} active={controller.form.schedule.allowed_days.includes(day.label)} />
                                                <S.dayLabel active={controller.form.schedule.allowed_days.includes(day.label)}>{day.name}</S.dayLabel>
                                            </S.day>
                                        ))}
                                    </S.allowedDays>
                                </S.field>
                            </>
                        ) : null}
                        <S.field>
                            <S.title style={{
                                textAlign: 'center'
                            }}>Campos do formulário</S.title>
                            <S.requestFields>
                                {controller?.form?.fields_array?.length === 0 ? (
                                    <S.field onClick={() => controller.addField()}>
                                        <S.title style={{
                                            textAlign: 'center',
                                            width: '100%',
                                            fontSize: '14px',
                                        }}>Nenhum campo adicionado</S.title>
                                    </S.field>
                                ) : null}
                                {(controller?.form?.fields_array ?? []).map((field: any, index: number) => (
                                    <S.field key={`field_${index}`} id={field.id}>
                                        <Text
                                            name={`field_title_${index}`}
                                            label={`Nome do campo ${index + 1}:`}
                                            value={field.title}
                                            onChange={(e: any) => {
                                                controller.form.fields_array[index].title = e.target.value;
                                                controller.setForm({ ...controller.form });
                                            }}
                                        />
                                        <Select
                                            name={`field_type_${index}`}
                                            label='Tipo do campo:'
                                            value={field.type}
                                            onChange={(e: any) => {
                                                controller.form.fields_array[index].type = e.target.value;
                                                controller.setForm({ ...controller.form });
                                            }}
                                            options={[
                                                { value: 'text', label: 'Texto curto' },
                                                { value: 'textarea', label: 'Texto longo' },
                                                { value: 'select', label: 'Seleção' },
                                                { value: 'checkbox', label: 'Checkbox' },
                                                { value: 'date', label: 'Data' },
                                            ]}
                                        />
                                        {field.type === 'date' && (
                                            <>
                                                <S.label>Permitir os dias:</S.label>
                                                <S.allowedDays>
                                                    {controller.general.days.map((day, dayIndex) => (
                                                        <S.day active={field.allowed_days?.includes(day.label)} key={`day_${dayIndex}`} onClick={() => controller.toggleFieldDay(index, day.label)}>
                                                            <S.dayIcon icon={field.allowed_days?.includes(day.label) ? controller.general.icons.faCheck : controller.general.icons.faTimes} active={field.allowed_days?.includes(day.label)} />
                                                            <S.dayLabel active={field.allowed_days?.includes(day.label)}>{day.name}</S.dayLabel>
                                                        </S.day>
                                                    ))}
                                                </S.allowedDays>
                                            </>
                                        )}
                                        {field.type === 'select' || field.type === 'radio' ? (
                                            <Text
                                                name={`field_options_${index}`}
                                                label='Opções (separadas por ;) :'
                                                value={field.options.join(';')}
                                                onChange={(e: any) => {
                                                    controller.form.fields_array[index].options = e.target.value.split(';');
                                                    controller.setForm({ ...controller.form });
                                                }}
                                            />
                                        ) : null}
                                        <S.optionsArea>
                                            <S.fieldOption style={{
                                                color: field.required ? controller.general.utilColors.linkBlue : 'black'
                                            }} onClick={() => controller.setFieldRequired(index)}>
                                                <S.optionIcon icon={controller.general.icons.faStar} />
                                                <S.optionLabel>Obrigatório</S.optionLabel>
                                            </S.fieldOption>
                                            <S.fieldOption onClick={() => controller.removeField(index)}>
                                                <S.optionIcon icon={controller.general.icons.faTrash} />
                                                <S.optionLabel>Excluir</S.optionLabel>
                                            </S.fieldOption>
                                            <S.fieldOption onClick={() => controller.duplicateField(index)}>
                                                <S.optionIcon icon={controller.general.icons.faCopy} />
                                                <S.optionLabel>Duplicar</S.optionLabel>
                                            </S.fieldOption>
                                        </S.optionsArea>
                                    </S.field>
                                ))}
                            </S.requestFields>
                        </S.field>
                    </S.fields>
                    <S.footer>
                        <S.button onClick={() => controller.addField()}>Adicionar campo</S.button>
                        <S.button onClick={() => controller.saveForm()}>Salvar</S.button>
                    </S.footer>
                </S.modal>
            </S.main>
            : null);
}

export default FormModal;