import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'
import * as brandIcons from '@fortawesome/free-brands-svg-icons'
import * as enums from './enums';
import DefaultLoading from '../components/app/DefaultLoading';
import DefaultLoadingFit from '../components/app/DefaultLoadingFit';
import NoPermissionComponent from '../components/app/NoPermissionComponent';
import api from './api';
import { useStore as zustand } from './zustand';
import fieldValidations from './fieldValidations';
import phoneMask from './phoneMask';
import countries from './countries.json';
import info_ibge from './info_ibge.json';
import currencies from './currencies.json';
import cnaes from './cnaes.json';
import cfops from './cfop_table.json';
import axios from 'axios'
import { toast } from 'sonner'
import { colors as utilColors } from './colors'
import PaymentMissing from '../components/app/PaymentMissing';

export const randomString = (length: number, chars: 'letters' | 'numbers' | 'special' | 'letnum' | 'all' = 'letnum') => {
  let result = ''
  let characters = ''
  switch (chars) {
    case 'letters':
      characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      break
    case 'numbers':
      characters = '0123456789'
      break
    case 'special':
      characters = '!@#$%^&*()_+~|}{[]:;?><,./-= '
      break
    case 'letnum':
      characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      break
    case 'all':
      characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~|}{[]:;?><,./-='
      break
    default:
      characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~|}{[]:;?><,./-='
      break
  }
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const days = [
  { name: 'Domingo', value: 0, label: 'sunday', abreviation: 'Dom' },
  { name: 'Segunda', value: 1, label: 'monday', abreviation: 'Seg' },
  { name: 'Terça', value: 2, label: 'tuesday', abreviation: 'Ter' },
  { name: 'Quarta', value: 3, label: 'wednesday', abreviation: 'Qua' },
  { name: 'Quinta', value: 4, label: 'thursday', abreviation: 'Qui' },
  { name: 'Sexta', value: 5, label: 'friday', abreviation: 'Sex' },
  { name: 'Sábado', value: 6, label: 'saturday', abreviation: 'Sáb' }
]

export const makeRandomHexColor = () => {
  return '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')
}

export const randomBinary = () => {
  return Math.floor(Math.random() * 2) == 1 ? true : false
}

export const randomNumber = (min: number = 0, max: number = Number.MAX_SAFE_INTEGER) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const cleanDocument = (document: string) => {
  return document.replace(/[^0-9]/g, "");
};

export const randomDate = (start: Date, end: Date) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};


export const checkValidUrl = (string: string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

const checkValidEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const GoToRoute = (route: string) => {
  window.location.href = route;
};

const buildResult = (result: string, maskChar: string) => {
  let completeResult = result
  completeResult = result?.indexOf(maskChar) == -1 ? result : result?.slice(0, result?.indexOf(maskChar))
  for (let i = 0; i < 2; i++) {
    completeResult = /[^A-Za-z0-9]/.test(completeResult[completeResult.length - 1]) ? completeResult?.slice(0, completeResult?.length - 1) : completeResult
  }
  return completeResult
}

export const customMask = (receivedValue: string, mask: string, maskChar = '#', onlyNumbers = false) => {
  let result = mask
  let value = ''
  if (onlyNumbers) {
    value = receivedValue.replace(/[^0-9]/g, '')
  }
  else {
    value = receivedValue.replace(/[^A-Za-z0-9]/g, '')
  }

  for (let counter = 0; counter < result!.length; counter++) {
    if (result![counter] == maskChar && value.length > 0) {
      result = result!.replace(maskChar, value[0])
      value = value.slice(1)
    }
  }

  if (receivedValue.length > mask.length) {
    result = result!.slice(0, result!.length - 1)
  }

  return buildResult(result!, maskChar);
}

export const validateCustomMask = (receivedValue: string, mask: string, maskChar = '#') => {
  let result = mask
  let value = receivedValue.replace(/[^A-Za-z0-9]/g, '')

  for (let counter = 0; counter < result!.length; counter++) {
    if (result![counter] == maskChar && value.length > 0) {
      result = result!.replace(maskChar, value[0])
      value = value.slice(1)
    }
  }

  if (receivedValue.length > mask.length) {
    result = result!.slice(0, result!.length - 1)
  }

  return result.includes(maskChar) ? false : true
}

export const convertColorHexToRgba = (hexColor: string) => {
  let hex = hexColor.replace(/^#/, '')

  if (hex.length === 3) {
    const fullHex = hex
      .split('')
      .map((char) => char.repeat(2))
      .join('')
    hex = fullHex
  }

  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return `rgba(${r},${g},${b},1)`
}

export const formatMoney = (value: string) => {
  let formattedValue = value.replace(/\D/g, '')
  formattedValue = (parseInt(formattedValue) / 100).toFixed(2).replace('.', ',')
  if (formattedValue.length > 6) {
    formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }
  formattedValue = isNaN(parseFloat(formattedValue)) ? '0,00' : formattedValue
  return formattedValue
}

export const formatNumber = (value: string | number, defaultNumber = 0, allowZero = true, allowNegatives = false) => {
  let formattedValue = value.toString().replace(/\D/g, '')
  if (formattedValue.length > 6) {
    formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }
  formattedValue = isNaN(parseFloat(formattedValue)) ? defaultNumber.toString() : formattedValue
  if (!allowZero && parseFloat(formattedValue) == 0) {
    formattedValue = defaultNumber.toString()
  }
  if (!allowNegatives && parseFloat(formattedValue) < 0) {
    formattedValue = defaultNumber.toString()
  }
  return formattedValue
}

const formatDate = (date: string, includeHours = false) => {
  return date.split('T')[0].split('-').reverse().join('/') + (includeHours ? ' ' + date.split('T')[1].split('.')[0] : '')
}

const formatName = (name: string) => {
  return name.split(' ')[0] + ' ' + (name.split(' ').length > 1 ? name.split(' ')[name.split(' ').length - 1] : '')
}

export const getCountries = () => {
  return countries
}

export const getAccount = () => {
  return JSON.parse(localStorage.getItem('account') || '{}')
}

export const getCompany = () => {
  return JSON.parse(localStorage.getItem('companyObject') || '{}')
}

export const getCurrentDateTime = () => {
  return new Date(+new Date - (1000 * 60 * 60 * 3)).toISOString()
}

export const notify = (message: string, type: 'success' | 'error' | 'info' | 'warning' = 'info') => {
  toast[type](message, {
    position: "bottom-right"
  });
}

export const copyToClipboard = (text: string) => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export const getIpAndLocation = async () => {
  return await axios.get('https://api.ipify.org?format=text').then(async (response) => {
    if (response.status === 200) {
      return await axios.get('http://ip-api.com/json/' + response.data).then((response) => {
        if (response.status === 200) {
          return response.data
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }).catch((err) => {
    console.log(err);
  });
}

let general = {
  randomString,
  cleanDocument,
  checkValidUrl,
  checkValidEmail,
  GoToRoute,
  FontAwesomeIcon,
  DefaultLoading,
  DefaultLoadingFit,
  PaymentMissing,
  NoPermissionComponent,
  randomBinary,
  randomNumber,
  randomDate,
  makeRandomHexColor,
  customMask,
  validateCustomMask,
  convertColorHexToRgba,
  formatMoney,
  getCountries,
  getAccount,
  getCompany,
  getCurrentDateTime,
  formatNumber,
  formatDate,
  formatName,
  notify,
  copyToClipboard,
  getIpAndLocation,
  days,
  phoneMask,
  fieldValidations,
  icons,
  brandIcons,
  enums,
  api,
  zustand,
  utilColors,
  info_ibge,
  currencies,
  cnaes,
  cfops,
  axios
};

export default general;
